import React from 'react';
// import PropTypes from 'prop-types';
import FamilienBetrieb from '../Icons/FamilienBetrieb';
import './Stage.scss';

const Stage = () => (
  <section className="Stage">
    <div className="Stage__layer">
      <FamilienBetrieb className="Stage__icon" />
      <p className="Stage__claim">
        Aus der Region
        <br />
        für die Region
      </p>
    </div>
    <picture>
      <source srcSet="/images/familie-sauer-lg.jpg" media="(min-width: 1480px)" />
      <source srcSet="/images/familie-sauer-md.jpg" media="(min-width: 768px)" />
      <img className="Stage__image" src="/images/familie-sauer-sm.jpg" alt="Familie Sauer" />
    </picture>
  </section>
);

Stage.propTypes = {};

Stage.defaultProps = {};

export default Stage;
