import React from 'react';
import PropTypes from 'prop-types';
import styles from './MenuSuggestion.module.scss';

const MenuSuggestion = ({ children, icon }) => (
  <article className={`${styles.menuSuggestion} ${icon ? '' : styles.noIcon}`}>
    {icon && (
      <div className={styles.iconWrapper}>
        <div className={styles.iconBox}>
          <img className={styles.icon} src={icon} alt="" />
        </div>
      </div>
    )}
    {children}
  </article>
);

MenuSuggestion.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

MenuSuggestion.defaultProps = {
  icon: null,
};

export default MenuSuggestion;
