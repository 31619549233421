import { useEffect } from 'react';

function useScroll() {
  useEffect(() => {
    const { hash } = window.location;

    if (hash.includes('#scroll=')) {
      setTimeout(() => {
        const id = hash.replace('#scroll=', '');
        const element = document.getElementById(id);
        const offsetPosition = element.offsetTop - 110;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }, 250);
    }
  }, []);
}

export default useScroll;
